import { graphql } from "gatsby";
import React, { useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import Divider from "../components/common/divider";
import Layout from "../components/site-wide/layout/layout";
import constants from "../utils/constants";

const trimImages = (imageSet) => {
  return imageSet.map(({ node }) => {
    return {
      src: node.childImageSharp.resize.src,
      // thumbnail: node.childImageSharp.resize.src,
      width: node.childImageSharp.resize.width,
      height: node.childImageSharp.resize.height,
    };
  });
};

const extractLinks = (images) => {
  return images.map((node) => {
    return node.src;
  });
};

const MenuPage = ({ data }) => {
  const images = trimImages(data.images.edges);
  const imageLinks = extractLinks(images);

  const [isLigthboxOpen, setIsLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  return (
    <Layout title={constants.INDEX.META.TITLE}>
      <Container className="mt-5 pt-5">
        <div className="text-center">
          <h1>Меню</h1>
          <Divider />
        </div>

        {isLigthboxOpen && (
          <>
            <Lightbox
              mainSrc={imageLinks[photoIndex]}
              nextSrc={imageLinks[(photoIndex + 1) % imageLinks.length]}
              prevSrc={
                imageLinks[
                  (photoIndex + imageLinks.length - 1) % imageLinks.length
                ]
              }
              onCloseRequest={() => {
                setIsLightboxOpen(false);
              }}
              onMovePrevRequest={() => {
                setPhotoIndex(
                  (photoIndex + imageLinks.length - 1) % imageLinks.length
                );
              }}
              onMoveNextRequest={() => {
                setPhotoIndex((photoIndex + 1) % imageLinks.length);
              }}
            />
          </>
        )}

        <div className="mt-5">
          <Row>
            {data.images.edges.map(({ node }, index) => (
              <Col lg={4}>
                <Image
                  className="mb-5 menu-gallery"
                  fluid
                  src={node.childImageSharp.resize.src}
                  alt="Меню"
                  onClick={() => {
                    setPhotoIndex(index);
                    setIsLightboxOpen(true);
                  }}
                />
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query MenuQuery {
    images: allFile(
      filter: { sourceInstanceName: { eq: "images-menu" } }
      sort: { fields: childImageSharp___resize___originalName }
    ) {
      edges {
        node {
          absolutePath
          childImageSharp {
            resize(width: 600, quality: 100) {
              src
              originalName
              width
              height
            }
          }
        }
      }
    }
  }
`;

export default MenuPage;
